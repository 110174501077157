// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        unitsFullLoad: false,
        unitsLiteLoad: false,
        units: [],
        unitEdit: null,
    },
    actions: {
        fetchUnits/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnits', time: Date.now() / 1000})

                const params = getApiProps('units', args)
                this.$api.units.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnits', inprogress: false})
                    });
            })
        },
        fetchUnitsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getUnits()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setUnits', response.data)
                            commit('setUnitsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isUnitsLiteLoad) {
                    return resolve(getters.units.length)
                }
                dispatch('fetchUnits', {lite: true})
                    .then((data) => {
                        commit('updateUnits', data)
                        commit('setUnitsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchUnitsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.units.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.units.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUnits', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateUnits', data)
                            })
                            .catch(() => {
                                dispatch('fetchUnits', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setUnitsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsChanged', inprogress: false})
                })
        },
        fetchUnitsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUnitsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnits', args)
                .then((data) => {
                    commit('updateUnits', data)
                    return dispatch('fetchUnits', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterUnits', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsChanged', inprogress: false})
                });
        },
        reloadUnitsAll({commit, dispatch}, args) {
            commit('clearUnitsMaxSpeed')
            return dispatch('fetchUnitsAllLite', args)
                .then(() => {
                    dispatch('fetchUnitsAllPages', args)
                })
        },

        fetchUnit({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnit', time: Date.now() / 1000})

                const params = getApiProps('units', args)
                this.$api.units.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && response.data) {
                            commit('updateUnit', response.data)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnit', inprogress: false})
                    });
            })
        },

        fetchUnitExtServices({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitExtServices', time: Date.now() / 1000})

                const params = getApiProps('unit_ext_services', args)
                this.$api.units.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && response.data) {
                            commit('updateUnit', response.data)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitExtServices', inprogress: false})
                    });
            })
        },
        fetchUnitProps({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id || !args.action) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitProps.'+args.action, time: Date.now() / 1000})

                let props = {...args}; delete props.id; delete props.action;
                this.$api.units.getProps(args.id, args.action, props)
                    .then((response) => {
                        if (response.status < 400 && response.data) {
                            //
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitProps.'+args.action, inprogress: false})
                    });
            })
        },


        saveUnit({dispatch}, unit) {
            let fn = (unit.id) ? 'updateUnit' : 'createUnit'
            return dispatch(fn, unit);
        },
        createUnit({commit, dispatch}, unit) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.create(unit, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUnit({commit, dispatch}, unit) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.update(unit.id, unit, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnit({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            if (!response.data) commit('deleteUnit', id)
                            else commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreUnit({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnitPermanently({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteUnit', id)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        sendUnitCmd({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'sendUnitCmd', time: Date.now() / 1000})

                this.$api.units.sendCmd(args.id, args)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            //???
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'sendUnitCmd', inprogress: false})
                    })
            })
        },

    },
    mutations: {
        setUnitsFullLoad(state, FullLoad) {
            state.unitsFullLoad = state.unitsFullLoad || FullLoad
        },
        setUnitsLiteLoad(state, LitaLoad) {
            state.unitsLiteLoad = state.unitsLiteLoad || LitaLoad
        },

        setUnits(state, nUnits) {
            nUnits = nUnits.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nUnits.sort(sortByName)
            state.units = nUnits
        },

        updateUnits(state, nUnits) {
            if (!state.units.length) {
                nUnits = nUnits.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nUnits.sort(sortByName)
                state.units = nUnits
                // const chunks = arraySplitIntoChunks(nUnits)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.units.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nUnits.forEach(function (nUnit) {
                if (nUnit?.name) nUnit.name_ = nUnit.name.toLocaleLowerCase()
                let i = state.units.findIndex(u => (u.id == nUnit.id))
                if (i < 0) {
                    state.units.push(nUnit) //(Object.freeze(nUnit))
                } else
                if (!state.unitsFullLoad || (state.units[i][changedField] != nUnit[changedField] && state.units[i][changedField]>0)) {
                    updateObjectByDiff(state.units[i], nUnit)
                    // delete nUnit.id
                    // nUnit = {...state.units[i], ...nUnit}
                    // state.units[i] = nUnit //Object.freeze(nUnit)
                }
            })

        },
        filterUnits(state, nUnits) {
            // let Ids = state.units.map(u=> u.id)
            let nIds = nUnits.map(u => u.id)
            let removedIds = state.units.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.units.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.units.splice(i, 1)
                }
            })
        },
        updateUnit(state, nUnit) {
            if (nUnit?.name) nUnit.name_ = nUnit.name.toLocaleLowerCase()
            let i = state.units.findIndex(u => (u.id == nUnit.id))
            if (i < 0) {
                state.units.push(nUnit) //(Object.freeze(nUnit))
            } else
            if (!state.unitsFullLoad || state.units[i][changedField] != nUnit[changedField]) {
                updateObjectByDiff(state.units[i], nUnit)
                // delete nUnit.id
                // nUnit = {...state.units[i], ...nUnit}
                // state.units[i] = nUnit //Object.freeze(nUnit)
            }
        },
        setUnitEdit(state, unit) {
            state.unitEdit = unit
        },
        deleteUnit(state, id) {
            let i = state.units.findIndex(u => (u.id == id))
            if (i != -1) {
                state.units.splice(i, 1)
            }
        },

        clearUnits(state) {
            state.units = []
            state.unitsFullLoad = false
        },
        clearUnitsMaxSpeed(state) {
            state.units = state.units.map(u => {
                return {...u, changed__time: 0, max_speed: null}
            })
        },
    },
    getters: {
        isUnitsFullLoad(state) {
            return state.unitsFullLoad
        },
        isUnitsLiteLoad(state) {
            return state.unitsLiteLoad
        },
        unitsOutputsById(state, getters) {
            return state.units.reduce((out, u) => {
                let lmsg = getters.unitsLmsgsByIds[u.id]
                out[u.id] = (u.outputs || []).map(o => {
                    let sensor = (lmsg?.sensors || {})[o.param] || {}
                    let value = sensor.value
                    return {
                        name: o.name,
                        view: o.name > '' && o.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            }, {})
        },
        unitsInputsById(state, getters) {
            return state.units.reduce((out, u) => {
                let lmsg = getters.unitsLmsgsByIds[u.id]
                out[u.id] = (u.inputs || []).map(i => {
                    let sensor = (lmsg?.sensors || {})[i.param] || {}
                    let value = sensor.value
                    return {
                        name: i.name,
                        view: i.name > '' && i.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            }, {})
        },

        units(state) {
            return state.units
        },
        firstActivatedUnitId(state, getters) {
            let units = getters.units
            units.sort(function (a, b) {
                let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            return units[0]?.id || 0
        },
        unitsByIds(state) {
            return state.units.reduce((unitsByIds, unit) => {
                unitsByIds[unit.id] = unit
                return unitsByIds
            }, {})
        },
        unitsAccessRightsByIds(state) {
            return state.units.reduce((unitsAccessRightsByIds, unit) => {
                unitsAccessRightsByIds[unit.id] = unit?.access_right || 0
                return unitsAccessRightsByIds
            }, {})
        },
        sortedUnitsIds(state) {
            let units = state.units
            units.sort(sortByName)
            return units.map(u => u.id)
        },
        getLockedUnits(state) {
            return state.units.filter(u => u[changedField] < 0).map(u => u.id)
        },

        getUnitsMarkers: (state, getters) => {
            return state.units.reduce((markersByUnitIds, unit) => {
                let lmsg = getters.unitsLmsgsByIds[unit.id] || {}
                let timeAgo = getters.unitsLmsgsTimeAgoByIds[unit.id] || {}
                // if (lmsg && lmsg?.latlng)// && unitLmsgs.timeAgo < 30*24*60*60)
                let status = '';
                if (!timeAgo) {
                    status = 'not_connection'
                } else
                if (timeAgo > 15 * 60) {
                    status = 'offline'
                } else
                if (lmsg?.status?.isMoving) {
                    status = 'moving';
                } else
                if (!lmsg?.status?.isMoving && !!lmsg?.status?.ignition?.value) {
                    status = 'idling';
                } else
                if (!lmsg?.status?.isMoving) {
                    status = 'parked';
                }
                let statusClass = consts?.statusesObjects?.find(s => s.id == status)?.class

                {
                    // let type = consts.FilterSearchLists.Units.types.find(t => t.id == unit.type)
                    // let unit_icon = "common__"+(type ? type.icon : 'car')+"_lg"
                    let unit_icon = unit.icon || 'map__car_type_sedan'
                    let icon_color = unit.icon_color || '#003B79'
                    let icon_has_halo = !(!unit.icon_halo)
                    let img_src = '/img/icons.svg#' + unit_icon
                    // '/img/unit/'+unit_icon+'.svg'
                    // '/img/unit/common__car.png'

                    markersByUnitIds[unit.id] = {
                        id: unit.id,
                        name: unit.name,
                        hw_type: unit.hw_type,
                        type: unit.type,
                        icon: unit_icon,
                        icon_color: icon_color,
                        icon_has_halo: icon_has_halo,

                        time: lmsg?.time,
                        latlng: lmsg?.latlng?.lat || lmsg?.latlng?.lng ? lmsg?.latlng : unit.coords,
                        course: lmsg?.course,
                        // speed: lmsg?.speed,
                        styleIcon: {
                            color: icon_color,
                            transform: lmsg?.course ? 'rotate(' + lmsg?.course + 'deg);' : '',
                        },
                        styleName: {},
                        img_src,
                        statusClass
                    }
                }
                return markersByUnitIds
            }, {})
        },
    }
}
