// import store from "@/store";
// import consts from "@/consts";
import md from '@/router/prometheus/portal/md.js';
import {routesUnits} from '@/router/prometheus/portal/units.js';
const units = routesUnits;
import units_groups from '@/router/prometheus/portal/units-groups.js';
import tracks from '@/router/prometheus/portal/tracks.js';
import geoitems from '@/router/prometheus/portal/geoitems.js';
import notifications from '@/router/prometheus/portal/notifications.js';
import services from '@/router/prometheus/portal/services.js';
import dashboard from '@/router/prometheus/portal/dashboard.js';
import reports from '@/router/prometheus/portal/reports.js';
import _routes from '@/router/prometheus/portal/_routes.js';
import personnel from '@/router/prometheus/portal/personnel.js';
import maintenance from '@/router/prometheus/portal/maintenance.js';
import alarms from '@/router/prometheus/portal/alarms.js';
import video from '@/router/prometheus/portal/video.js';
import fuel_cards from '@/router/prometheus/portal/fuel_cards.js';
import dashboard_gauges from '@/router/prometheus/portal/dashboard-gauges.js';
import locator from '@/router/prometheus/portal/locator.js';
// import help from '@/router/prometheus/portal/help.js';

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    ...md,
    ...dashboard,
    ...reports,
    ...notifications,
    ..._routes,
    ...personnel,
    ...maintenance,
    ...alarms,
    ...video,
    ...fuel_cards,
    ...dashboard_gauges,
    ...tracks,
    ...geoitems,
    ...services,
    ...units,
    ...units_groups,
    ...locator,
]

export default routes