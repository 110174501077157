import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        geozones: [],
        geozonesFullLoad: false,
        geozonesLiteLoad: false,
    },
    actions: {
        fetchGeozones/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeozones', time: Date.now() / 1000})

                const params = getApiProps('geozones', args)
                this.$api.geozones.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchGeozones', inprogress: false})
                    });
            })
        },
        fetchGeozonesAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getGeozones()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setGeozones', response.data)
                            commit('setGeozonesLiteLoad', true)
                            if(consts.app.$isIntuit) {
                                dispatch('setAllGeoitemsShowOnMap')
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeozonesAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeozonesAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isGeozonesLiteLoad) {
                    return resolve(getters.geozones.length)
                }
                dispatch('fetchGeozones', {lite: true})
                    .then((data) => {
                        commit('updateGeozones', data)
                        commit('setGeozonesLiteLoad', true)
                        if(consts.app.$isIntuit) {
                            dispatch('setAllGeoitemsShowOnMap')
                        }
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeozonesAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeozonesAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchGeozonesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeozonesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.geozones.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.geozones.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchGeozones', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateGeozones', data)
                            })
                            .catch(() => {
                                dispatch('fetchGeozones', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setGeozonesFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchGeozonesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeozonesChanged', inprogress: false})
                })
        },
        fetchGeozonesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isGeozonesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchGeozonesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchGeozones', args)
                .then((data) => {
                    commit('updateGeozones', data)
                    return dispatch('fetchGeozones', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterGeozones', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeozonesChanged', inprogress: false})
                });
        },
        reloadGeozonesAll({commit, dispatch}, args) {
            commit('clearGeozones')
            return dispatch('fetchGeozonesAllLite', args)
                .then(() => {
                    dispatch('fetchGeozonesAllPages', args)
                })
        },

        saveGeozone({dispatch}, geozone) {
            let fn = (geozone.id) ? 'updateGeozone' : 'createGeozone'
            return dispatch(fn, geozone);
        },
        createGeozone({commit, dispatch}, geozone) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones')
                this.$api.geozones.create(geozone, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeozone', {...response.data})//, points: geozone.points
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateGeozone({commit, dispatch}, geozone) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones')
                this.$api.geozones.update(geozone.id, geozone, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeozone', {...response.data})//, points: geozone.points
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteGeozone({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.geozones.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteGeozone', id)
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeozonesFullLoad(state, FullLoad) {
            state.geozonesFullLoad = state.geozonesFullLoad || FullLoad
        },
        setGeozonesLiteLoad(state, LitaLoad) {
            state.geozonesLiteLoad = state.geozonesLiteLoad || LitaLoad
        },

        setGeozones(state, nGeozones) {
            //state.geozones = nGeozones.map(g => Object.freeze(g))
            nGeozones = nGeozones.map(g => {
                if (g?.name) g.name_ = g.name.toLocaleLowerCase()
                return g //Object.freeze(u)
            })
            nGeozones.sort(sortByName)
            state.geozones = nGeozones
        },

        updateGeozones(state, nGeozones) {
            if (!state.geozones.length) {
                state.geozones = nGeozones.map(u => Object.freeze(u))
                return true
            }

            nGeozones.forEach(function (nGeozone) {
                let i = state.geozones.findIndex(u => (u.id == nGeozone.id))
                if (i < 0) {
                    state.geozones.push(Object.freeze(nGeozone))
                } else
                if (!state.geozonesFullLoad || state.geozones[i][changedField] != nGeozone[changedField]) {
                    // updateObjectByDiff(state.geozones[i], nGeozone)
                    delete nGeozone.id
                    state.geozones[i] = Object.freeze({...state.geozones[i], ...nGeozone})
                }
            })
        },
        filterGeozones(state, nGeozones) {
            let nId = nGeozones.map(nu => nu.id)
            let removedIds = state.geozones.filter(g => !nId.includes(g.id)).map(g => g.id)
            removedIds.forEach(removedId => {
                let i = state.geozones.findIndex(g => (g.id == removedId))
                if (i != -1) {
                    state.geozones.splice(i, 1)
                }
            })
        },
        updateGeozone(state, nGeozone) {
            let i = state.geozones.findIndex(u => (u.id == nGeozone.id))
            if (i < 0) {
                state.geozones.push(Object.freeze(nGeozone))
            } else
            if (!state.geozonesFullLoad || state.geozones[i][changedField] != nGeozone[changedField]) {
                // updateObjectByDiff(state.geozones[i], nGeozone)
                delete nGeozone.id
                state.geozones[i] = Object.freeze({...state.geozones[i], ...nGeozone})
            }
        },
        deleteGeozone(state, id) {
            let i = state.geozones.findIndex(g => (g.id == id))
            if (i != -1) {
                state.geozones.splice(i, 1)
            }
        },
        clearGeozones(state) {
            state.geozones = []
            state.geozonesLiteLoad = false
            state.geozonesFullLoad = false
        },
    },
    getters: {
        isGeozonesLiteLoad(state) {
            return state.geozonesLiteLoad
        },
        isGeozonesFullLoad(state) {
            return state.geozonesFullLoad
        },
        geozones(state) {
            return state.geozones
        },
        geozonesByIds(state) {
            // return state.geozonesObj
            return state.geozones.reduce((geozonesById, g) => {
                geozonesById[g.id] = g
                return geozonesById
            }, {})
        },

        geozonesLOptions(state) {
            if (!state.geozonesFullLoad) return [];
            return state.geozones
                .filter(g => g?.type && g?.points?.length)
                .map(geozone => {
                    let color = (geozone.color ? geozone.color : '#00ff00')
                    let options = {
                        geozoneId: geozone.id,
                        type: geozone.type,
                        name: geozone.name,
                        description: geozone.descr,
                        maxSpeed: geozone.maxSpeed,
                        points: geozone.points,
                        radius: geozone.radius,
                        area: geozone.surface_area,
                        color: color,
                        fillColor: color,
                        fillOpacity: 0.5
                    }
                    if (geozone.type === 'corridor') {
                        options.layerType = 'polyline'
                        options.polyline_radius = geozone.radius;
                        options.corridor = geozone.radius / 2;
                    }
                    return options
                })
        },
    }
}
