<template>
    <div class="head__horizontal-line horizontal-line aside-relative">
        <button class="head__notifications button button_view_icon button_theme_primary button_has-counter mr__8"
                v-if="$isPrometheus"
                @click="goto('notifications')"
        >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__online_notifications" /></span> </span>
            <span class="button__counter counter"> {{count}} </span>
        </button>
        <button class="button button_view_user-menu button_dropdown-menu button_theme_primary"
                @click.stop="switchShowUserMenu"
        >
<!--            <span class="button__icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__user_sm" /></span> </span>-->
<!--            <span class="button__icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__menu_sm" /></span> </span>-->
            <span class="button__text">{{userLetters}}</span>
        </button>
        <UserDropdownMenu :class="[{'dropdown-menu__meta-list': hasList}, userMenuPositionClass]" :show-menu="showUserMenu" />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UserDropdownMenu from "./UserDropdownMenu.vue";

export default {
    name: "UserMenu",
    components: {UserDropdownMenu},
    data() {
        return {
            count: 2,
            isOpen: false
        }
    },
    computed: {
        ...mapGetters([
            "apiToken",
            "getAppUser",
            "getAppUserLetters",
            "showUserMenu",
        ]),
        user(){
            return this.getAppUser
        },
        userLetters(){
            return this.getAppUserLetters
        },
        hasList(){
            return !!this.$route.meta.list
        },
        userMenuPositionClass(){
            return this.hasList ? 'position_right-bottom' : 'position_left-top'
        }
    },
    methods: {
        ...mapMutations([
            "switchShowUserMenu",
        ]),
        ...mapActions([
        ]),
        goto(path){
            this.$router.push('/'+path)
        },
    },
    mounted() {
        // console.log('UserDropdownMenu', this)
    },
    updated() {
        //console.log('FieldGroup_input updated', this.id, this.error)
    }
}
</script>

<style scoped>

</style>