// import consts from "@/consts";
// import store from '@/store;'
// import moment from "moment";
import statistics from '@/router/prometheus/admin/statistics.js';
import users from '@/router/prometheus/admin/users.js';
import units from '@/router/prometheus/admin/units.js';
import recycleBin from '@/router/prometheus/admin/recycleBin.js';
import userActions from '@/router/prometheus/admin/userActions.js';
import objectChanges from '@/router/prometheus/admin/objectChanges.js';

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.admin') ? [] : [
    ...statistics,
    ...users,
    ...units,
    ...recycleBin,
    ...userActions,
    ...objectChanges,
]

export default routes