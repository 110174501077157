<template>
    <UnitInfo_info :unitId="unitId"/>
    <router-view :class="$attrs.class"/>

    <Teleport to="#sectionInfoByPage">
        <router-view name="info"></router-view>
    </Teleport>

    <Teleport to="#sectionInfoByRoute">
        <router-view :class="$attrs.class" name="sectionInfoByRoute" v-on:close-dialog="onCloseSectionInfoByRoute"></router-view>
    </Teleport>
</template>

<script>
import UnitInfo_info from '@/components/units/pageInfo/UnitInfo_content.prometheus.vue';
import {mapActions, mapGetters} from "vuex";
import {accessRight} from "@/lib/access-rights";
export default {
    name: "UnitInfo",
    props: {
        unitId: [String, Number],
    },
    data() {
        return {
            showInfo: true,
        }
    },
    components: {
        UnitInfo_info,
    },
    computed: {
        ...mapGetters([
            "unitsByIds",
            "unitsAccessRightsByIds",
        ]),
        routeName() {
            return this.$route.name
        },
        unit() {
            return this.unitsByIds[this.unitId]
        },
        hasVideo() {
            return !!this.unit?.video
        },
        isUnitAccessRight_edit() {
            return this.unitsAccessRightsByIds[this.unitId] >= accessRight.edit
        },
    },
    methods: {
        ...mapActions([
            "setMapPaddingBounds",
        ]),
        onCloseSectionInfoByRoute(){
            this.setRoute.returnToParent(this.$route, this.$router)
        },
    },
    watch: {
        routeName: {
            handler: function (nVal, OldVal){
                if(nVal != OldVal){
                    this.showInfo = true
                }
            },
            deep: true,
        }
    },
    mounted() {
        this.setMapPaddingBounds(null)
    }
}
</script>

<style lang="scss" scoped>

</style>
<style>
.show-info--close #sectionInfoByPage {
    display: none;
}
</style>