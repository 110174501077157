<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{ $t('User.title.User settings') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__16">
                <FieldGroup_input
                        :field-group_class="'mb__12'"
                        :input_type="'email'"
                        :id="'login'"
                        :label="UserLoginLabel"
                        :error="v$.user.login.$errors"
                        v-model="user.login"
                />

                <h3 class="form-subtitle mb__12">{{ $t('User.Language') }}</h3>
                <SegmentControl
                    :segment-control-class="'mb__24'"
                    :items="langs"
                    v-model="$i18n.locale"
                />

                <h3 class="form-subtitle mb__12">{{ $t('User.Measure') }}</h3>
                <SegmentControl
                        :segment-control-class="'mb__12'"
                        :items="unitsOfMeasure"
                        :showIconActive="true"
                        v-model="user.units_of_measure"
                >
                    <template v-slot:name-icon="props">
                        <span class="info-position">
                            <SvgIcon name="common__info"
                                     class="icon"
                                     :title="unitsOfMeasureInfo[props.id]"
                            />
                        </span>
                    </template>
                </SegmentControl>

                <FieldGroup_select
                    :field-group_class="'field-group mb__12'"
                    :id="'time_zone'"
                    :label="$t('User.Time zone')"
                    :items="getSettingsValues.time_zone"
                    :sortBy="(a, b) => { return Math.sign(a.id-b.id) }"
                    :error="v$.user.time_zone.$errors"
                    v-model="user.time_zone"
                />
                <CheckboxControl
                    :control-class="'mb__12'"
                    :label="$t('User.Daylight Saving Time')"
                    :error="v$.user.dst.$errors"
                    v-model="user.dst"
                />
                <div style="display: flex">
                    <FieldGroup_select
                        :field-group_class="'field-group mb__12 mr__8'"
                        :id="'format'"
                        :field_id="'php_format'"
                        :field_name="'name'"
                        :label="$t('User.Date format')"
                        :items="getDateFormats"
                        :error="v$.user.settings.dateFormat.$errors"
                        v-model="user.settings.dateFormat"
                    />
                    <FieldGroup_select
                        :field-group_class="'field-group mb__12'"
                        :id="'format'"
                        :field_id="'php_format'"
                        :field_name="'name'"
                        :label="$t('User.Time format')"
                        :items="getTimeFormats"
                        :error="v$.user.settings.timeFormat.$errors"
                        v-model="user.settings.timeFormat"
                    />
                </div>
                <div class="aside__info info">
                    <SectionCurrentDateTime
                        :control-class="'mb__44'"
                        :timezone-offset="timeZone"
                        :format="userDateTimeFormat"
                    />
                </div>

                <h3 class="form-subtitle mb__12">{{ $t('User.Color track by speed') }}</h3>
                <div class="color-slider color-slider_width_400 ml__12 mb__64">
                    <vue-slider id="speed"
                                :min="3"
                                :max="200"
                                v-model="slider.modelValue"
                                :marks="slider.marks"
                                :minRange="slider.minRange"
                                :process="slider.colors"
                                :tooltip="'always'"
                                :enable-cross="false"
                                :clickable="false"
                    >
                        <template v-slot:process="{ style, index }">
                            <div class="vue-slider-process custom-class"
                                 :style="style"
                                 @click="onClick_slider(index)"
                            >
                                <!-- Can add custom elements here -->
                            </div>
                        </template>
                        <!-- <template v-slot:tooltip="{ value }">-->
                        <!--     <div class="custom-tooltip">{{ value }}</div>-->
                        <!-- </template>-->
                    </vue-slider>
                </div>
            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>

    <!-- onSave popup -->
    <custom-modal v-if="modalWindows?.onSave" v-bind="modalWindows?.onSave?.props">
        <SectionLoading v-if="modalWindows?.loading"/>
        <p class="size-14" v-else>{{ $t('popup.Are you sure') }}</p>
    </custom-modal>
</template>

<script>
    import {calcUTCOffset, diffObjectsRecursiveExceptions} from "@/lib/lib";
    import {useToast} from "vue-toastification";
    import {mapActions, mapGetters} from "vuex";
    import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";

    //https://nightcatsama.github.io/vue-slider-component/#/basics/simple
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    import useVuelidate from "@vuelidate/core";
    import {email, helpers, required} from "@vuelidate/validators";
    import consts from "@/consts";

    export default {
        name: "UserSettings",
        emits:['close-dialog'],
        props: [],
        components: {
            SectionCurrentDateTime,
            'vue-slider': VueSlider,
        },
        data() {
            return {
                UserLoginLabel: (this.$isIntuit) ? this.$i18n.t('User.Email') : this.$i18n.t('User.Login'),
                defColors: ['#29F499', '#027AFD', '#FF8100', '#FF2D00'],
                slider: {
                    // min: 3,
                    // max: 200,
                    marks: [3, 50, 100, 150, 200],
                    minRange: 10,
                    modelValue: [50, 80, 120],
                    //https://nightcatsama.github.io/vue-slider-component/#/basics/process
                    colors: dotsPos => [
                        [0, dotsPos[0], {backgroundColor: this.defColors[0]}],
                        [dotsPos[0], dotsPos[1], {backgroundColor: this.defColors[1]}],
                        [dotsPos[1], dotsPos[2], {backgroundColor: this.defColors[2]}],
                        [dotsPos[2], 100, {backgroundColor: this.defColors[3]}],
                    ],
                },
                modalWindows: {},
                user: {},
                errors: {},
                toast: useToast(),
                v$: useVuelidate({ $externalResults: this.errors } ),
            }
        },
        validations() {
            let validations = {
                user: {
                    login: {
                        required: helpers.withMessage(this.$t('errors.required', {name: this.UserLoginLabel}), required),
                        api: () => {
                            if(!this.errors.login || !this.errors.login.length) return { $valid:true }
                            return {
                                $valid: false,
                                $messages: this.errors.login,
                            }
                        }
                    },
                    time_zone: {
                        required: helpers.withMessage(this.$t('errors.required', {name: this.$t('User.Time zone')}), required),
                        // integer: helpers.withMessage(this.$t('errors.integer', {name: this.$t('User.Time zone')}), integer),
                        api: () => {
                            if(!this.errors.time_zone || !this.errors.time_zone.length) return { $valid:true }
                            return {
                                $valid: false,
                                $messages: this.errors.time_zone,
                            }
                        }
                    },
                    dst: {
                        api: () => {
                            if(!this.errors.dst || !this.errors.dst.length) return { $valid:true }
                            return {
                                $valid: false,
                                $messages: this.errors.dst,
                            }
                        }
                    },
                    settings: {
                        dateFormat: {
                            api: () => {
                                if(!this.errors.dateFormat || !this.errors.dateFormat.length) return { $valid:true }
                                return {
                                    $valid: false,
                                    $messages: this.errors.dateFormat,
                                }
                            }
                        },
                        timeFormat: {
                            api: () => {
                                if(!this.errors.timeFormat || !this.errors.timeFormat.length) return { $valid:true }
                                return {
                                    $valid: false,
                                    $messages: this.errors.timeFormat,
                                }
                            }
                        },
                    }
                }
            }
            if(this.$isIntuit) {
                validations.user.login.email = helpers.withMessage(this.$t('errors.email', {name: this.UserLoginLabel}), email);
            }
            return validations;
        },
        computed: {
            ...mapGetters([
                "getAppUser",
                "getSettingsValues",
                "getAppUserSettings",
                "getAppUserUnits",
                // "getDateFormats",
                "getDateFormat",
                // "getTimeFormats",
                "getTimeFormat",
            ]),
            langs() {
                return this.$consts.langs || []
            },
            getTimeFormats(){
                return this.$consts.timeFormats
            },
            getDateFormats(){
                return this.$consts.dateFormats
            },
            userSettings(){
                return this.getAppUserSettings || {}
            },
            unitsOfMeasureWithUnits() {
                return this.getSettingsValues?.UnitsOfMeasure
            },
            unitsOfMeasureList() {
                return Object.keys(this.unitsOfMeasureWithUnits || {})
            },
            unitsOfMeasure() {
                return this.unitsOfMeasureList?.map(u => {
                    return {name: this.$te('measure.'+u) ? this.$t('measure.'+u) : u, id: u }
                })
            },
            unitsOfMeasureInfo() {
                return this.unitsOfMeasureList
                    .reduce((out, m) => {
                        let measureParams = this.unitsOfMeasureWithUnits[m]
                        let text = Object.keys(consts.unitsOfMeasures).map(measure => !measureParams[measure] ? '' :
                            (this.$t('UserSettings.UserUnits.' + measure) + ': ' + measureParams[measure])
                        ).filter(t => t).join('\n');
                        out[m] = text
                        return out;
                    }, {})
            },
            timeZone(){
                return calcUTCOffset(this.user.time_zone*1, this.user.dst)
            },
            userDateTimeFormat(){
                return this.getDateFormat + ' ' + this.getTimeFormat
            },
            trackSpeedColors(){
                let trackSpeedColors = this.user?.settings?.trackSpeedColors || [];
                if (!trackSpeedColors?.length) return null;

                let values = [0,...this.slider.modelValue, 999]
                return trackSpeedColors.map((obj, i) => {
                    return {
                        ...obj,
                        'from': (values[i] || 0),
                        'to': (values[i + 1] || 999),
                    }
                })
            },
        },
        watch: {
            user: {
                handler: function () {
                    this.errors = {}
                    this.v$.$touch()
                    // this.errors = this.v$.$errors
                    //     .reduce((errors, e) => {
                    //         if(!errors[e.$propertyPath]) errors[e.$propertyPath] = []
                    //         errors[e.$propertyPath].push(e.$message)
                    //         return errors
                    //     },{})
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions([
                "saveAppUser",
                "fetchAppUser",
            ]),
            // onClick_slider(index){
            //    if(this.$isDevelopment) console.log(index);
            // },
            onCancel(){
                let lang = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.lang')
                //lang = this.$consts.langs.map(l => l.id).includes(lang)? lang : 'en'
                this.$i18n.locale = lang

                this.$emit('close-dialog')
            },
            modalClose() {
                this.modalWindows = {}
            },
            async onSave() {
                localStorage.setItem(process.env.VUE_APP_PRODUCT+'.lang', this.$i18n.locale)
                this.user.lang = this.$i18n.locale
                this.user.settings.trackSpeedColors = this.trackSpeedColors
                //if(this.$isDevelopment) console.log(trackSpeedColors)


                //create popup on save
                this.modalWindows = {
                    'onSave': {
                        props:{
                            title: this.$t('User.title.User settings'),
                            buttons:[
                                {id: 'ok', fn: () => this.onSaveUser()},
                                {id: 'cancel', fn: () => this.modalClose()},
                            ]
                        }
                    }
                }
            },
            onSaveUser() {
                this.modalWindows.loading = true
                let user = {}
                let beforeSave = []
                // if(...){
                //     beforeSave.push('nameFunction')
                // }

                Promise.all(beforeSave.map(a => this[a]()))
                    .then((response) => {
                        //if(this.$isDevelopment) console.log('beforeSave',response)
                        return !response.find(v => v !== true)
                    })
                    .then((response) => {
                        user = {...this.user}
                        if (user.id) {
                            let org = JSON.parse(this.org)
                            let diffUser = diffObjectsRecursiveExceptions(org, user)
                            user = {id: user.id, ...diffUser}
                        }

                        return response && this._saveUser(user)
                    })
                    .then((response) => {
                        this.modalClose()
                        if(response) {
                            this.$emit('close-dialog')
                        }
                        return response
                    })
            },
            _saveUser(user) {
                //if(this.$isDevelopment) console.log(user)
                return this.saveAppUser(user)
                    .then((/*res*/) => {
                        //if(this.$isDevelopment) console.log(res)
                        this.errors = {}
                        this.toast.success(this.$t("success!"))
                        return true
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                            if (error.response.status == 422) {
                                this.errors = error.response.data.reduce((errors, error) => {
                                    errors[error.field] = [error.message]
                                    return errors
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                        return false
                    })
            }
        },
        created() {
            let user = {...this.getAppUser}
            let settings = user?.settings || {}
            this.user = {id: user.id, login: user.login, email: user.email, lang: user.lang, dst: user.dst, time_zone: user.time_zone, units_of_measure: user.units_of_measure, settings}
            //this.settings = {...this.getAppUser.settings}// user.settings.dateFormat,// user.settings.timeFormat,
            let values = (settings.trackSpeedColors || []).map(o => o.to)
            this.slider.modelValue = values.slice(0, 3)
            this.org = JSON.stringify(this.user)
            //if(this.$isDevelopment) console.log('UserSettings created', {...this.user})
        },
        mounted() {
            //if(this.$isDevelopment) console.log('UserSettings mounted', {...this.user})
            localStorage.setItem(process.env.VUE_APP_PRODUCT+'.lang', this.$i18n.locale)
        },
        updated() {
            //if(this.$isDevelopment) console.log('UserSettings updated', {...this.user})
        },
    }
</script>

<style lang="scss" scoped>
.info-position {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
}
</style>