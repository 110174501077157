let routerNames = {
    route: {
        main: 'route',
    },
    dashboard: {
        main: 'Dashboard',
    },
    dealer: {
        main: 'Dealer',
    },
    help: {
        main: 'Help',
    },
    app: {
        user: {
            edit: {
                main: 'App.user.edit'
            }
        }
    },
    users: {
        main: 'Users',
        actions: 'Users_actions',
        history: 'Users.history',
        edit: {
            main: 'Users.edit',
            password: 'Users.change-password',
            create: 'Users.create',
            setup_dns: 'Users.setup-dns',
            customize: 'Users.edit.customize',
        }
    },
    md: {
        main: 'MD',
        widget: 'MD.widget',
    },
    units: {
        main: 'Units',
        id: 'Units.id',
        fastGeozone: 'Units.fastGeozone',
        all: {
            main: 'Units.all',
            info: 'Units.all.info',
        },
        lite: 'Units.lightView',
        locator: 'Units.locator',
        chat: 'Units.chat',
        history: 'Units.history',
        commands: {
            main: 'Units.command',
            group: 'Units.groupCommand',
        },
        info: 'Units.info',
        fast_geozone: 'Units.fastGeozone',
        edit: {
            main: 'Units.edit',
            group: 'Units.groupEdit',
            create: 'Units.create',
        },
        video: {
            main: 'Units.video',
            live: 'Units.liveVideo',
            event: 'Units.video-event',
            request: 'Units.video-request',
        },
        type: {
            xtrack: {
                main: 'Units.xtrack',
                info: 'Units.xtrack.info',
                edit: 'Units.xtrack.edit',
                fastGeozone: 'Units.xtrack.fastGeozone',
                sensors: 'Units.xtrack.sensors',
                track: 'Units.xtrack.track',
            },
            xvision: {
                main: 'Units.xvision',
                info: 'Units.xvision.info',
                edit: 'Units.xvision.edit',
                fastGeozone: 'Units.xvision.fastGeozone',
                liveVideo: 'Units.xvision.live-video',
                videoEvent: 'Units.xvision.video-event',
                videoRequest: 'Units.xvision.video-request',
                sensors: 'Units.xvision.sensors',
                snapshots: 'Units.xvision.snapshots',
                track: 'Units.xvision.track',
                smartSearch: 'Units.xvision.smartSearch',
            },
            pro_cargo_vision: {
                main: 'Units.proCargoVision',
                info: 'Units.proCargoVision.info',
                edit: 'Units.proCargoVision.edit',
                fastGeozone: 'Units.proCargoVision.fastGeozone',
                liveVideo: 'Units.proCargoVision.live-video',
                videoEvent: 'Units.proCargoVision.video-event',
                videoRequest: 'Units.proCargoVision.video-request',
                sensors: 'Units.proCargoVision.sensors',
                snapshots: 'Units.proCargoVision.snapshots',
                track: 'Units.proCargoVision.track',
            },
            xpower: {
                main: 'Units.xpower',
                info: 'Units.xpower.info',
                edit: 'Units.xpower.edit',
                fastGeozone: 'Units.xpower.fastGeozone',
                sensors: 'Units.xpower.sensors',
                control: 'Units.xpower.control',
                analytics: 'Units.xpower.analytics',
                history: 'Units.xpower.history',
            },
            xcool: {
                main: 'Units.xcool',
                info: 'Units.xcool.info',
                edit: 'Units.xcool.edit',
                fastGeozone: 'Units.xcool.fastGeozone',
                reefer: 'Units.xcool.reefer',
            },
            xgrafana: {
                main: 'Units.xgrafana',
                info: 'Units.xgrafana.info',
                edit: 'Units.xgrafana.edit',
                fastGeozone: 'Units.xgrafana.fastGeozone',
                map: 'Units.xgrafana.map',
            },
            xlink: {
                main: 'Units.xlink',
                info: 'Units.xlink.info',
                edit: 'Units.xlink.edit',
                fastGeozone: 'Units.xlink.fastGeozone',
            },
            obd: {
                main: 'Units.OBD',
                info: 'Units.OBD.info',
                edit: 'Units.OBD.edit',
                fastGeozone: 'Units.OBD.fastGeozone',
                map: 'Units.OBD.map'
            },
        },
        track: {
            main: "Units.track",
            xid: "Units.track.xid",
            event_xid: "Units.track.event.xid"
        }
    },
    units_groups: {
        main: 'UnitsGroups',
        id: 'UnitsGroups.id',
        map: 'UnitsGroups.map',
        edit: 'UnitsGroups.edit'
    },
    services: {
        main: 'Services',
        id: 'Services.id',
        list: 'Services.list',
        service: 'Services.service'
    },
    tracks: {
        main: 'Tracks',
        lite: 'Tracks.lightView',
        xid: 'Tracks.xid',
        event_xid: 'Tracks.event-xid',
        move_on_track: 'Tracks.moveOnTrack',
        video: {
            event: 'Tracks.video-event',
            request: 'Tracks.video-request',
        }
    },
    notificationsevents: {
        main: 'NotificationsEvents',
        lite: 'NotificationsEvents.lightView',
        edit: {
            main: 'NotificationsEvents.edit',
        },
        popup: {
            main: 'NotificationsEvents.popup',
        }
    },
    geoitems: {
        main: 'Geoitems',
        lite: 'Geoitems.lightView',
        edit: {
            main: 'Geoitems.edit',
        }
    },
    object_changes: {
        main: 'ObjectsChanges',
    },
    recycle_bin: {
        main: 'RecycleBin',
        restore: 'RecycleBin.restore',
        edit: {
            unit: 'RecycleBin.unit-edit',
        },
        history: {
            unit: 'RecycleBin.unit-history',
            user: 'RecycleBin.user-history',
        }
    },
    statistics: {
        main: 'Statistics',
        statement: 'Statistics.statement',
        mileage: 'Statistics.mileage',
    },
    analytics: {
        main: 'analytics'
    },
    routes: {
        main: 'Routes',
    },
    locator: {
        main: 'Locator',
    },
    alarms: {
        main: 'Alarms',
    },
    dashboard_gauges: {
        main: 'DashboardGauges',
    },
    fuel: {
        main: 'Fuel',
    },
    fuel_cards: {
        main: 'FuelCards',
    },
    maintenance: {
        main: 'Maintenance',
    },
    personnel: {
        main: 'Personnel',
    },
    reports: {
        main: 'Reports',
        template: 'Reports.template',
        system: 'Reports.system',
        mongo: 'Reports.mongo',
    },
    video: {
        main: 'Video',
    },
    shipment: {
        main: 'Shipment',
        id: 'Shipment.id',
        map: 'Shipment.map',
        edit: 'Shipment.edit',
        editView: 'Shipment.editView',
    }
}

const consts = {
    timeoutReadyComponent: 300,
    querySettings: {
        filter: {'filter[changed]': -600},
        pageSize: 300,
        maxPages: 8,
        // unitsPageSize: 100,
    },
    routerNames,
    templates: {
        unitLite: {
            routeName: routerNames.units.lite,
            extensions: {
                byUnitHwType: ['xpower'],// xlink - ???
            },
        },
        // tracksLite: {
        //     routeName: routerNames.tracks.lite,
        //     extensions: {},
        // },
        // geoitemsLite: {
        //     routeName: routerNames.geoitems.lite,
        //     extensions: {},
        // },
        // notificationseventsLite: {
        //     routeName: routerNames.notificationsevents.lite,
        //     extensions: {},
        // }
    },
    shortWidth: 690,
    map: {
        center: [25.761681, -80.191788],
    },
    dealer_shop: 'https://83fbbd-2.myshopify.com/',
    shopify: {
        dealer: {
            domain: '83fbbd-2.myshopify.com',
            storefrontAccessToken: '97f09cc3f6cdb53725424cffe62d69ff'
        },
        client: {
            domain: '83fbbd-2.myshopify.com',
            storefrontAccessToken: '97f09cc3f6cdb53725424cffe62d69ff'
        },
    },
    indexTimeout: {
        parkings: 5 * 60,
        trips: 5 * 60,
        videoRecords: 5 * 60,
    },
    units_of_measure:['imperial','metric'],
    recyclebin_time_left: 60*24*3600,
    menus: {
        portal: [
            {id: "units", name: routerNames.units.main, title: ("menu.Units"), icon: "menu__car"},
            {id: "md",    name: routerNames.md?.main,   title: ("MD"), icon: "menu__md"},
            {id: "tracks", name: routerNames.tracks.main, title: ("menu.Tracks"), icon: "menu__tracks"},
            {id: "geoitems", name: routerNames.geoitems.main, title: ("menu.Geoitems"), icon: "menu__geoitems"},
            {id: "notificationsevents", name: routerNames.notificationsevents.main, title: ("menu.Notice"), icon: "menu__notifications"},
            {id: "reports",         name: routerNames.reports?.main,        title: ("menu.Reports"), icon: "menu__reports"},
            {id: "help", name: routerNames.help.main, title: ('menu.Help'), icon: "menu__help"},
            // {id: "dashboard", name: routerNames.dashboard.main, title: 'menu.Dashboard', icon: "menu__statistic"},
            {id: "dealer", name: routerNames.dealer.main, title: ("menu.Dealer"), icon: "menu__dealer"},
            {id: "shipment", name: routerNames.shipment.main, title: ("menu.Shipment"), icon: "menu__shipment", hide_menu_enable: true},
            // {id: "analytics", name: routerNames.analytics.main, title: ('menu.Analytics'), icon: "menu__analytics"},
            // {id: "test", name: routerNames.test.main, title: ('menu.TestPage'), icon: "menu__test"},
        ],
        admin: [
            {id: "statistics", name: routerNames.statistics.main, title: ('menu.Dashboard'), icon: "menu__dashboard"},
            {id: "users", name: routerNames.users.main, title: ("menu.Users"), icon: "menu__personnel"},
            {id: "units", name: routerNames.units.main, title: ("menu.Units"), icon: "menu__car"},
            {id: "recycle-bin", name: routerNames.recycle_bin.main, title: ("menu.Recycle Bin"), icon: "menu__delete"},
            {id: "user-actions", name: routerNames.users.actions, title: ("menu.User actions"), icon: "menu__history"},
            // {id: "objs-changes", name: routerNames.object_changes.main, title: ("menu.User actions"), icon: "menu__history"},
            // {id: "dealer", name: routerNames.dealer.main, title: ("menu.Dealer"), icon: "menu__dealer"},
            // {id: "help", name: routerNames.help.main, title: ('menu.Help'), icon: "menu__help"},
            // {id: "test",            name: routerNames.test.main,           title: ('menu.TestPage'), icon: "menu__test"},
        ],
        mainSize: 9,
    },
    defaultSettingsLayers: {
        units: {visible: false, showInList: true, showLayer: true},
        geoitems: {visible: false, showInList: true, showLayer: true},
    },
    FilterSearchLists: {
        Units: {
            signals: [
                {id: 'on', name: ('ON line'), translate: ('Units.signals.ON line'), icon: 'common__car'},
                {id: 'off', name: ('OFF line'), translate: ('Units.signals.OFF line'), icon: 'common__car'},
            ],
            movements: [
                {id: 'moving', name: ('Moving'), translate: ('Units.movements.Moving'), icon: 'common__car'},
                {id: 'stopped', name: ('Stopped'), translate: ('Units.movements.Stopped'), icon: 'common__car'},
                {id: 'ignition', name: ('Ignition on'), translate: ('Units.movements.Ignition on'), icon: 'common__car'},
            ],
            car_icons: [
                {id: 'map__car_type_sedan', name: ('Sedan'), translate: ('Units.icons.Sedan'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_pickup', name: ('Pickup'), translate: ('Units.icons.Pickup'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_truck', name: ('Truck'), translate: ('Units.icons.Truck'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_motorbike', name: ('Motorbike'), translate: ('Units.icons.Motorbike'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_container', name: ('Container'), translate: ('Units.icons.Container'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_flatbed', name: ('Flatbed'), translate: ('Units.icons.Flatbed'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_reefer', name: ('Reefer'), translate: ('Units.icons.Reefer'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_trailer', name: ('Trailer'), translate: ('Units.icons.Trailer'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_taxi', name: ('Taxi'), translate: ('Units.icons.Taxi'), group:'Static', isColored: false},
                {id: 'map__car_type_police', name: ('Police'), translate: ('Units.icons.Police'), group:'Static', isColored: false},
                {id: 'map__car_type_ambulance', name: ('Ambulance'), translate: ('Units.icons.Ambulance'), group:'Static', isColored: false},
            ],
            camera: [
                {id: 'all', name: 'All Cameras', translate: 'Units.camera.All Cameras', icon: 'camera-online', color: 'gray'},
                {id: 'online', name: 'Cameras Online', translate: 'Units.camera.Cameras Online', icon: 'camera-online', color: '#0ec900'},
                {id: 'offline', name: 'Cameras Offline', translate: 'Units.camera.Cameras Offline', icon: 'camera-offline', color: 'red'},
            ],
            types: [
                {id: 'truck', name: ('Truck'), translate: ('Units.types.Truck'), icon: 'unit_type_truck', filterByTypes: true},
                {id: 'container', name: ('Container'), translate: ('Units.types.Container'), icon: 'unit_type_container', filterByTypes: true},
                {id: 'flatbed', name: ('Flatbed'), translate: ('Units.types.Flatbed'), icon: 'unit_type_flatbed', filterByTypes: true},
                {id: 'reefer', name: ('Reefer'), translate: ('Units.types.Reefer'), icon: 'unit_type_reefer', filterByTypes: true},
                {id: 'trailer', name: ('Trailer'), translate: ('Units.types.Trailer'), icon: 'unit_type_reefer', filterByTypes: true},
                // {id: 'complex', name: ('Complex'), translate: ('Units.types.Complex'), icon: 'common__complex'},
                // {id: 'boat', name: ('Boat'), translate: ('Units.types.Boat'), icon: 'common__boat'},
                // {id: 'bus', name: ('Bus'), translate: ('Units.types.Bus'), icon: 'common__bus'},
                // {id: 'car', name: ('Car'), translate: ('Units.types.Car'), icon: 'common__car'},
                // {id: 'dry trailer', name: ('Dry Trailer'), translate: ('Units.types.Dry Trailer'), icon: 'common__dry_trailer'},
                // {id: 'engine', name: ('Engine'), translate: ('Units.types.Engine'), icon: 'common__engine'},
                // {id: 'fuel tank', name: ('Fuel Tank'), translate: ('Units.types.Fuel Tank'), icon: 'common__fuel_tank'},
                // {id: 'fuel tanker', name: ('Fuel Tanker'), translate: ('Units.types.Fuel Tanker'), icon: 'common__fuel_tanker'},
                // {id: 'generator', name: ('Generator'), translate: ('Units.types.Generator'), icon: 'common__generator'},
                // {id: 'jet ski', name: ('Jet Ski'), translate: ('Units.types.Jet Ski'), icon: 'common__jet_ski'},
                // {id: 'motorcycle', name: ('Motorcycle'), translate: ('Units.types.Motorcycle'), icon: 'common__motorcycle'},
                // {id: 'plant machine', name: ('Plant Machine'), translate: ('Units.types.Plant Machine'), icon: 'common__plant_machine'},
                // {id: 'pump', name: ('Pump'), translate: ('Units.types.Pump'), icon: 'common__pump'},
                // {id: 'rv', name: ('RV'), translate: ('Units.types.RV'), icon: 'common__rv'},
                // {id: 'reefer gt', name: ('Reefer GT'), translate: ('Units.types.Reefer GT'), icon: 'common__reefer_gt'},
                // {id: 'reefer trailer', name: ('Reefer Trailer'), translate: ('Units.types.Reefer Trailer'), icon: 'common__reefer_trailer'},
                // {id: 'stationary unit', name: ('Stationary Unit'), translate: ('Units.types.Stationary Unit'), icon: 'common__stationary_unit'},
                // {id: 'tank', name: ('Tank'), translate: ('Units.types.Tank'), icon: 'common__tank'},
                // {id: 'tractor', name: ('Tractor'), translate: ('Units.types.Tractor'), icon: 'common__tractor'},
                // {id: 'train', name: ('Train'), translate: ('Units.types.Train'), icon: 'common__train'},
                // {id: 'truck', name: ('Truck'), translate: ('Units.types.Truck'), icon: 'common__truck'},
                // {id: 'van', name: ('Van'), translate: ('Units.types.Van'), icon: 'common__van'},
                // {id: 'water tanker', name: ('Water Tanker'), translate: ('Units.types.Water Tanker'), icon: 'common__water_tanker'},
            ],
        },
        Events: {
            types: [
                {id: 'lost connection', type: 'event', name: ('Lost connection'), translate: ('Events.types.Lost connection'), icon: 'notifications__lost-connection'},
                {id: 'low battery', type: 'event', name: ('Low battery'), translate: ('Events.types.Low battery'), icon: 'notifications__low-battery'},
                {id: 'max speed', type: 'event', name: ('Max speed'), translate: ('Events.types.Max speed'), icon: 'notifications__max-speed'},
                {id: 'input', type: 'event', name: ('Input alert'), translate: ('Events.types.Input alert'), icon: 'notifications__input'},
            ],
        },
        NotificationsEvents: {
            types: [
                // {id: '1', type: 'notification-event', name: ('Parameters control'), translate: ('NotifEvents.types.Parameters control'), icon: 'notifications__parameter_control'},
                {id: '2', type: 'notification-event', name: ('Entry into geosite'), translate: ('NotifEvents.types.Entry into geosite'), icon: 'notifications__geozone_entrance_control'},
                {id: '3', type: 'notification-event', name: ('Exit from geosite'), translate: ('NotifEvents.types.Exit from geosite'), icon: 'notifications__geozone_exit_control'},
                // {id: '4', type: 'notification-event', name: ('Approaching geopoint'), translate: ('NotifEvents.types.Approaching geopoint'), icon: 'notifications__approaching_geopoint'},
                // {id: '5', type: 'notification-event', name: ('Distance geopoint'), translate: ('NotifEvents.types.Distance geopoint'), icon: 'notifications__distance_geopoint'},
                // {id: '6', type: 'notification-event', name: ('Lost connection'), translate: ('NotifEvents.types.Lost connection'), icon: 'notifications__lost-connection'},
                // {id: '7', type: 'notification-event', name: ('Sensors control'), translate: ('NotifEvents.types.Sensors control'), icon: 'notifications__sensor_control'},
                // {id: '9', type: 'notification-event', name: ('Approaching object'), translate: ('NotifEvents.types.Approaching object'), icon: 'notifications__approaching_object'},
                // {id: '10', type: 'notification-event', name: ('Overspeeding by GIS'), translate: ('NotifEvents.types.Overspeeding by GIS'), icon: 'notifications__overspeeding_gis'},
                // {id: '11', type: 'notification-event', name: ('Maintenance'), translate: ('NotifEvents.types.Maintenance'), icon: 'notifications__maintenance'},
                // {id: '12', type: 'notification-event', name: ('Fuel control'), translate: ('NotifEvents.types.Fuel control'), icon: 'notifications__fuel_control'},
                // {id: '17', type: 'notification-event', name: ('Towing'), translate: ('NotifEvents.types.Towing'), icon: 'notifications__towing'},

                //{id: '8', type: 'notification-event', name: ('Track control'), translate: ('NotifEvents.types.Track control'), icon: 'notifications__'},
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__condition_control'},//???
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__movement'},
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__parking'},
                //'attribute' => 'type_condition', 'images' => ['movement' => 'movement','parking' => 'parking',],

                //{id: '15', type: 'notification-event', name: ('Control of presence inside of geozone'), translate: ('NotifEvents.types.Control of presence inside of geozone'), icon: 'notifications__'},
                //{id: '16', type: 'notification-event', name: ('Control of absence inside of geozone'), translate: ('NotifEvents.types.Control of absence inside of geozone'), icon: 'notifications__'},

            ],
        },
        VideoEvents: {
            types: [
                {id: 'video-event', type: 'video-event', name: ('Video event'), translate: ('VideoEvents.types.Video event'), icon: 'notifications__video-event'},
                // {id: 'ADAS', type: 'video-event', name: ('ADAS Video event'), translate: ('VideoEvents.types.ADAS Video event'), icon: 'notifications__video-event'},
                // {id: 'other', type: 'video-event', name: ('Other Video event'), translate: ('VideoEvents.types.other Video event'), icon: 'notifications__video-event'},
            ],
        },
        Shipment: {
            types: [
                {id: 'documents', name: ('Documents'), translate: ('Shipment.Documents'), icon: 'shipment__documents'},
                {id: 'box', name: ('Box'), translate: ('Shipment.Box'), icon: 'shipment__box'},
                {id: 'container', name: ('Container'), translate: ('Shipment.Container'), icon: 'shipment__container'},
                {id: 'pallet', name: ('Pallet'), translate: ('Shipment.Pallet'), icon: 'shipment__pallet'},
                {id: 'food', name: ('Food'), translate: ('Shipment.Food'), icon: 'shipment__food'},
            ],
            alerts: [
                {id: 'temperature', name: ('Temperature'), translate: ('Shipment.sensors.Temperature'), icon: 'shipment__documents'},
                {id: 'humidity', name: ('Humidity'), translate: ('Shipment.Humidity'), icon: 'shipment__box'},
                {id: 'light', name: ('Light'), translate: ('Shipment.sensors.Light'), icon: 'shipment__container'},
                {id: 'battery', name: ('Battery'), translate: ('Shipment.sensors.Battery'), icon: 'shipment__pallet'},
                {id: 'shock', name: ('Shock'), translate: ('Shipment.sensors.Shock'), icon: 'shipment__food'},
            ],
        }
    },
    ReeferTypes: [
        {
            'title': '-',
            'type':  '',
        },
        {
            'title': 'Thermo King',
            'type':  'thermo_king',
            'icon': 'reefer__king',
        },
        {
            'title': 'Carrier',
            'type':  'carrier',
            'icon': 'reefer__carrier',
        },
    ],
    unitTypesByHwType: {
        default: [
            {
                'title': 'Camera',
                'value':  'camera',
            },
            {
                'title': 'Trailer',
                'value':  'trailer',
            },
            {
                'title': 'Vehicle',
                'value':  'vehicle',
            },
            {
                'title': 'Asset',
                'value':  'asset',
            },
            {
                'title': 'Truck',
                'value':  'truck',
            },
            {
                'title': 'Container',
                'value':  'container',
            },
            {
                'title': 'Flatbed',
                'value':  'flatbed',
            },
            {
                'title': 'Reefer',
                'value':  'reefer',
            },

        ],
        xtag: [
            {
                'title': 'BLE Tag',
                'value':  'ble_tag',
            }
        ],
    },
    Licenses: {
        params: [
            {days: 30, text: '1 month', color: '#FE6D23'},
            {days: 90, text: '3 months', color: '#FFB700'},
            {days: 180, text: '6 months', color: '#90C20B'},
            {days: 365, text: '1 year', color: '#01CB25'},
        ],
    },
    uploadError: {
        "invalidFileExtension": "Invalid file extension",
        "invalidMaxFileSize": "Invalid max file size",
        "invalidMixFileSize": "Invalid min file size"
    },
    unitsList: {
        useTypeForTemplate: ['xpower', 'xlink', 'xtag']
    },
    videoFormats: [
        {id: 'mp4',    name: 'mp4'},
        {id: 'images', name: 'images'},
    ],
    timeline: {
        eventpoint_width_px: 10,
        eventsgroup__width_px: 24+16,
        startsEnd__width_px: 24,
        movePointWidth: 20,
        groupingTime: 3600 / 2,
        requestTimeFormat: 'HH:mm:ss',
        datetime_error: 'Current time is out of available time frame, video can\'t be requested',
        requestSeconds: [
            {id: 1, name: '0'},
            {id: 10, name: '10'},
            {id: 20, name: '20'},
            {id: 30, name: '30'},
            {id: 40, name: '40'},
            {id: 60, name: '60'},
            {id: 120, name: '120'},
        ],
    },
    timelineFilter: [
        {id: 'event', name: 'Events'},
        {id: 'video-event', name: 'Video events', video: true},
        {id: 'notification-event', name: 'Notifications events'},
        {id: 'video-request', name: 'Video requests', video: true},
    ],
    statusesObjects: [
        {id: 'moving',        color: '#2FC76B',  title: 'In Motion', class: 'status-unit-moving', z_index: 3},
        {id: 'driving',        color: '#2FC76B',  title: 'In Motion', class: 'status-unit-moving', z_index: 3},
        {id: 'parked',        color: '#0276F0',  title: 'Parked', class: 'status-unit-parked'},
        {id: 'parking',        color: '#0276F0',  title: 'Parked', class: 'status-unit-parked'},
        {id: 'idling',        color: '#FD3939',  title: 'Idling', class: 'status-unit-idling', z_index: 2},
        {id: 'offline',       color: '#ff0000',  title: 'Offline', class: 'status-unit-offline'},
        {id: 'not_connection',color: '#000000',  title: 'Not connection', class: 'status-unit-not_connection'},
        {id: 'standBy',        color: '#FF8B1C',  title: 'Standby', class: 'status-unit-stand-by', z_index: 0},
        {id: 'alerts',        color: '#ff0000',  title: 'Alerts', class: 'status-shipment-alert', z_index: 3},
        {id: 'shipment',  color: 'transparent',  title: 'Shipment', class: 'status-shipment', z_index: 0},
        {id: 'shipment-time',  color: '#2FC76B',  title: 'Shipment time', class: 'status-shipment-time', z_index: 3},
        {id: 'shipment-start-delay',  color: 'orange',  title: 'Shipment Start Delay', class: 'status-shipment-start-delay', z_index: 3},
        {id: 'shipment-end-delay',  color: 'yellow',  title: 'Shipment End Delay', class: 'status-shipment-end-delay', z_index: 3},
    ],
    sensorsSettings: {
        default: {color: '#CCD5E07F', colorDanger: '#ff0000'},
        temperature: {color: '#00D285', colorDanger: '#ff0000'},
        humidity: {color: '#CC8CEB', colorDanger: '#ff0000'},
        lighting: {color: '#FFB232', colorDanger: '#ff0000'},
        light: {color: '#FFB232', colorDanger: '#ff0000'},
        battery: {color: '#4D668C', colorDanger: '#ff0000'},
        shock: {color: '#E07715', colorDanger: '#ff0000'},
    },
    shipment: {
        mods: ['Road','Air','Ocean','Rail'],
        extendedShipmentByStatus: ['completed', 'cancelled'],
        route: {
            radius: 300,
            color: {
                start: '#047AFBFF',
                end: '#28B25FFF',
            }

        },
    }
}
export default consts