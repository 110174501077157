<template>
    <span class="list__count">{{ tagsCount }}</span>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_digital_matter_count',
        props: {
            unitId: [String, Number],
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
                "isUnitsLmsgsFullLoad",
            ]),
            unitLmsg() {
                return this.unitsLmsgsByIds[this.unitId]
            },
            tagsCount() {
                return this.unitLmsg?.tags?.length || 0
            }
        },
        beforeUpdate() {
            // console.log(this.inc)
            // this.inc = 0
        },
        updated: function() {
            // console.log(`UnitsList_unit_timeago ${this.unitId} updated`);
        },
        mounted() {
            // console.log(this.$root)
        },
    }
</script>