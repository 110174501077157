// import JQuery from 'jquery'
// window.$ = window.jQuery = JQuery

import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
app.config.unwrapInjectedRef = true

import i18n from "@/i18n.js";
app.use(i18n)

import config from "@/config";
app.config.globalProperties.$config = Object.freeze(config)

import consts from "@/consts"
consts.langs = consts.langs.filter(l => i18n.global.availableLocales.includes(l.id))
app.config.globalProperties.$consts = Object.freeze(consts)

app.config.globalProperties.$product_name = String(process.env.VUE_APP_PRODUCT_NAME)
app.config.globalProperties.$package = String(process.env.VUE_APP_PACKAGE)
const [product, site] = String(process.env.VUE_APP_PRODUCT).split('.', 2)
app.config.globalProperties.$ver={product,site, name: process.env.VUE_APP_PRODUCT_NAME}
app.config.globalProperties.$product = product
app.config.globalProperties.$site = site
app.config.globalProperties.$isAdminSite = String(process.env.VUE_APP_PACKAGE).includes('admin')
app.config.globalProperties.$isPortalSite = String(process.env.VUE_APP_PACKAGE).includes('portal')
app.config.globalProperties.$isDevelopment = (process.env.NODE_ENV !== 'production')
app.config.globalProperties.$isIntuit = (product === consts.productsName.intuit)
app.config.globalProperties.$isPrometheus = (product === consts.productsName.prometheus)

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
app.use(Toast, config.toast.options);

import { Slider } from '@ckpack/vue-color' // Chrome
// app.component('chrome-picker', Chrome)
app.component('slider-picker', Slider)

import SvgIcon from '@/components/_base/SvgIcon.vue'
app.component('SvgIcon', SvgIcon)
import FieldGroup_input from '@/components/_base/FieldGroup_input.vue'
app.component('FieldGroup_input', FieldGroup_input)
import FieldGroup_password from '@/components/_base/FieldGroup_password.vue'
app.component('FieldGroup_password', FieldGroup_password)
import FieldGroup_textarea from '@/components/_base/FieldGroup_textarea.vue'
app.component('FieldGroup_textarea', FieldGroup_textarea)
import FieldGroup_select from '@/components/_base/FieldGroup_select.vue'
app.component('FieldGroup_select', FieldGroup_select)
import FieldGroup_flatpickr_time from '@/components/_base/FieldGroup_flatpickr_time.vue'
app.component('FieldGroup_flatpickr_time', FieldGroup_flatpickr_time)
import FieldGroup_flatpickr_datetime from '@/components/_base/FieldGroup_flatpickr_datetime.vue'
app.component('FieldGroup_flatpickr_datetime', FieldGroup_flatpickr_datetime)
import FieldGroup_flatpickr from '@/components/_base/FieldGroup_flatpickr.vue'
app.component('FieldGroup_flatpickr', FieldGroup_flatpickr)
import FieldGroup_kendo_timepicker from '@/components/_base/FieldGroup_kendo_timepicker.vue'
app.component('FieldGroup_kendo_timepicker', FieldGroup_kendo_timepicker)
import FieldGroup_k_combobox from '@/components/_base/FieldGroup_k_combobox.vue'
app.component('FieldGroup_k_combobox', FieldGroup_k_combobox)
import FieldGroup_k_dropdownlist from '@/components/_base/FieldGroup_k_dropdownlist.vue'
app.component('FieldGroup_k_dropdownlist', FieldGroup_k_dropdownlist)
import FieldGroup_color_chooser from '@/components/_base/FieldGroup_color_chooser.vue'
app.component('FieldGroup_color_chooser', FieldGroup_color_chooser)
import FieldGroup_upload from '@/components/_base/FieldGroup_upload.vue'
app.component('FieldGroup_upload', FieldGroup_upload)
import FieldGroup_upload_multiple_img from '@/components/_base/FieldGroup_upload_multiple_img.vue'
app.component('FieldGroup_upload_multiple_img', FieldGroup_upload_multiple_img)
import FieldGroupImageList from '@/components/_base/FieldGroup_image_list.vue'
app.component('FieldGroup_image_list',FieldGroupImageList)
import FieldGroup__base_wrap from '@/components/_base/FieldGroup__base_wrap.vue'
app.component('FieldGroup__base_wrap',FieldGroup__base_wrap)
import CheckboxControl from '@/components/_base/CheckboxControl.vue'
app.component('CheckboxControl', CheckboxControl)
import CheckboxControlsForArray from '@/components/_base/CheckboxControlsForArray.vue'
app.component('CheckboxControlsForArray', CheckboxControlsForArray)
import SegmentControl from '@/components/_base/SegmentControl.vue'
app.component('SegmentControl', SegmentControl)
import SegmentControl_icon from '@/components/_base/SegmentControl_icon.vue'
app.component('SegmentControl_icon', SegmentControl_icon)
import SegmentControl_slider from '@/components/_base/SegmentControl_slider.vue'
app.component('SegmentControl_slider', SegmentControl_slider)
import ButtonIcon_dual_status from '@/components/_base/ButtonIcon_dual_status.vue'
app.component('ButtonIcon_dual_status', ButtonIcon_dual_status)
import SectionLoading from '@/components/_base/SectionLoading.vue'
app.component('SectionLoading', SectionLoading)
import FieldGroup_info from '@/components/_base/FieldGroup_info.vue'
app.component('FieldGroup_info', FieldGroup_info)
import WeatherWidget from '@/components/_base/widgets/WeatherWidget.vue'
app.component('WeatherWidget', WeatherWidget)
import SpinnerIcon from '@/components/_base/spinner/SpinnerIcon.vue'
app.component('SpinnerIcon', SpinnerIcon)
import EditSegmentControl from '@/components/_base/wrappers/EditSegmentControl.vue'
app.component('EditSegmentControl', EditSegmentControl)
import EditSegmentControl_vertical from '@/components/_base/wrappers/EditSegmentControl_vertical.vue'
app.component('EditSegmentControl_vertical', EditSegmentControl_vertical)
import EditWizard from '@/components/_base/wrappers/EditWizard.vue'
app.component('EditWizard', EditWizard)
import Chart_donut from "@/components/_base/charts/Chart_donut.vue";
app.component('Chart_donut', Chart_donut)
import Chart_series from "@/components/_base/charts/Chart_series.vue";
app.component('Chart_series', Chart_series)
import ChartWrap from "@/components/_base/charts/ChartWrap.vue";
app.component('ChartWrap', ChartWrap)
import AdminWrapPage from "@/components/_base/AdminWrapPage.vue";
app.component('AdminWrapPage', AdminWrapPage)
import TableFilterSelect from "@/components/_base/tables/TableFilterSelect.vue";
app.component('TableFilterSelect', TableFilterSelect)
import TableFilterDate from "@/components/_base/tables/TableFilterDate.vue";
app.component('TableFilterDate', TableFilterDate)
import TableFilterInput from "@/components/_base/tables/TableFilterInput.vue";
app.component('TableFilterInput', TableFilterInput)
import CopyTextComponent from "@/components/_base/CopyTextComponent.vue";
app.component('CopyTextComponent', CopyTextComponent)

import {
    vfmPlugin,
    VueFinalModal,
    // ModalsContainer,
} from 'vue-final-modal'
app.use(vfmPlugin)
app.component(VueFinalModal)
// app.component(ModalsContainer)
import CustomModal from './components/_base/modal/CustomModal.vue'
app.component('CustomModal', CustomModal)

//???
// import dateFilter from '@/filters/date.filter'
// app.filter('date', dateFilter)

//???
//you need to import the CSS manually (in case you want to override it)
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
// import DateRangePicker from 'vue2-daterange-picker'
// app.use(DateRangePicker)

// "vue-auto-virtual-scroll-list": "^0.3.0",
// import VueAutoVirtualScrollList from 'vue-auto-virtual-scroll-list'
// app.use(VueAutoVirtualScrollList)

// "vue-observe-visibility": "^1.0.0",
// import VueObserveVisibility from 'vue-observe-visibility'
// app.use(VueObserveVisibility)

// "vue-virtual-scroll-list": "^2.3.2",
// import VirtualList from 'vue-virtual-scroll-list'
// app.component('virtual-list', VirtualList)//+style-VDom

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from 'vue-virtual-scroller'
app.use(VueVirtualScroller)

// import useVuelidate from '@vuelidate/core'
// app.use(useVuelidate)
// app.config.globalProperties.v$ = useVuelidate()
// app.config.globalProperties.$v = useVuelidate()

// Kendo UI library
// import '@progress/kendo-ui' // This will import the entire Kendo UI library

// @progress/kendo-vue-grid
// import { Grid } from '@progress/kendo-vue-grid'
// app.component('kendo-grid', Grid);

// @progress/kendo-vue-treelist
import { TreeList } from '@progress/kendo-vue-treelist';
app.component('k-treelist', TreeList);

import {DropDownList} from '@progress/kendo-vue-dropdowns';
app.component('k-dropdownlist', DropDownList)

//https://github.com/DarkC0der11/vue-skeletor
import { Skeletor } from "vue-skeletor";
app.component('SkeletorLoader', Skeletor)
// import {ComboBox} from '@progress/kendo-vue-dropdowns';
// app.component('k-combobox': ComboBox)

// As an alternative, you could import only the scripts that are used by a specific widget:
// import '@progress/kendo-ui/js/kendo.autocomplete' // Imports only the AutoComplete script and its dependencies
// import '@progress/kendo-ui/js/kendo.combobox' // Imports only the ComboBox script and its dependencies
// import '@progress/kendo-ui/js/kendo.dropdownlist' // Imports only the DropDownList script and its dependencies
// import '@progress/kendo-ui/js/kendo.multiselect' // Imports only the MultiSelect script and its dependencies
// import '@progress/kendo-ui/js/kendo.multicolumncombobox' // Imports only the MultiColumnComboBox script and its dependencies
// import {
    // AutoComplete,
    // ComboBox,
    // DropDownList,
    // MultiSelect,
    // MultiColumnComboBox,
    // MultiColumnComboBoxColumn,
    // DropdownsInstaller
// } from '@progress/kendo-dropdowns-vue-wrapper'

// app.use(DropdownsInstaller)
// app.component('k-autocomplete', AutoComplete)
// app.component('k-combobox', ComboBox)
// app.component('k-dropdownlist', DropDownList)
// app.component('k-multiselect', MultiSelect)
// app.component('k-multicolumncombobox', MultiColumnComboBox)
// app.component('k-multicolumncomboboxcolumn', MultiColumnComboBoxColumn)

import '@progress/kendo-theme-default/dist/all.css';

// // import Vue plugin
// import VueSvgInlinePlugin from "vue-svg-inline-plugin";
// // import polyfills for IE if you want to support it
// import "vue-svg-inline-plugin/src/polyfills";
// // use Vue plugin without options
// app.use(VueSvgInlinePlugin);
// // use Vue plugin with options
// VueSvgInlinePlugin.install(Vue, {
//   attributes: {
//     data: [ "src" ],
//     remove: [ "alt" ]
//   }
// });

// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// am4core.useTheme(am4themes_animated);

//recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'
if(config?.recaptcha?.siteKey) {
    app.use(VueReCaptcha, {
        siteKey: config.recaptcha.siteKey,
        loaderOptions: {
            useRecaptchaNet: true,
            autoHideBadge: false,
            explicitRenderParameters: {
                badge: "bottomleft",
            }
        }
    })
}

// import { createPinia } from 'pinia'
// app.use(createPinia())

import shopify from './shopify';
app.config.globalProperties.$shopify = shopify

import store from './store'
import api from "./api.js";
app.config.globalProperties.$api = api
store.$api = api
//store.$collator = new Intl.Collator(),
app.use(store)

import router from './router'
app.use(router)
// router.app = app

//import i18n from './i18n'
// app.use(i18n)

// router.isReady().then(() => app.mount('#app'))
app.mount('#app')
