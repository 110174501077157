import { createStore } from 'vuex'
import consts from "@/consts";

import app from './modules/app'
import map from './modules/map'
import security from './modules/security'
import access_rights from './modules/access-rights'

import users from './modules/users'
import drivers from './modules/drivers'
import appointments from './modules/appointments'
import customize from './modules/customize'
import units from './modules/units'
import units_groups from './modules/units-groups';
import units_lmsgs from './modules/units-lmsgs'
import video_statuses from './modules/video_statuses'
import unit_locator from './modules/unit-locator'

import tive_shipments from './modules/tive-shipments'
import tive_shipment_alerts from './modules/tive-shipment-alerts'
import tive_shipment_tracks from './modules/tive-shipment-tracks'
import tive_shipment_templates from './modules/tive-shipment-templates'
import tive_devices from './modules/tive-devices'
import tive_carriers from './modules/tive-carriers'
import tive_alert_presets from './modules/tive-alert-presets'
import tive_locations from './modules/tive-locations'
import tive_users from './modules/tive-users'
import shipments_types from './modules/shipments_types'

import geoitems from './modules/geoitems'
import geozones from './modules/geozones'
import geozones_groups from './modules/geozones-groups';
import geopoints from './modules/geopoints'
import geopoints_groups from './modules/geopoints-groups';
import notifications from './modules/notifications'
// import notifications_types from './modules/notifications-types'

import notifications_history from './modules/notifications_history'

import units_hwtypes from "./modules/units_hwtypes";

import trips from './modules/trips'
import parkings from './modules/parkings'
import idlings from './modules/idlings'
import stops from './modules/stops'
import events from './modules/events'
import screenshots from './modules/screenshots'
import video_events from './modules/video_events'
import video_requests from './modules/video_requests'
import video_records from './modules/video_records'
import tracks from './modules/tracks'

import maintenance from "./modules/maintenance"
import maintenanceVendors from "./modules/maintenance-vendors";
import maintenanceContacts from "./modules/maintenance-contacts";
import maintenanceContactGroups from "./modules/maintenance-contact-groups";
import maintenanceParts from "./modules/maintenance-parts";
import maintenanceServiceTasks from "./modules/maintenance-service-tasks";
import maintenanceIssues from "./modules/maintenance-issues";
import maintenanceWorkOrders from "./modules/maintenance-work-orders";
import maintenanceInspections from "./modules/maintenance-inspections";
import maintenanceHistory from "./modules/maintenance-history";
import maintenanceNotifications from "./modules/maintenance-notifications";
import maintenanceExpenses from "./modules/maintenance-expenses";

import weather from "./modules/weather";

import reports_templates from "./modules/reports_templates";
import reports from "./modules/reports";
import statistics from "./modules/statistics";

export default createStore({
    state: {
        fetchByPage: [],
        last_call: {},
        theme: consts.themes.white,
    },
    getters: {
        lastCall(state){
            return state.last_call
        },
        getFetchByPage(state){
            return state.fetchByPage
        },
        getTheme(state) {
            let theme = state.theme
            if(!Object.values(consts.themes).includes(theme)) {
                console.error('themes:', consts?.themes, 'theme:', theme)
                theme = consts.themes.white
            }
            return theme
        }
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme
            localStorage.setItem(process.env.VUE_APP_PRODUCT +'.theme', theme); //toDo fix ???
        },
        setLastCall(state, args){
            let name = args?.name || ''
            let time = 0
            if(args?.time){
                time = args.time + consts.api.defaults.auth.timeout
            } else
            if(args?.inprogress === false){
                time = state.last_call[args.name]
                time -= consts.api.defaults.auth.timeout
            }
            state.last_call[name] = time
        },
        addFetchFnsByPage(state, fetchFns){
            if(!fetchFns) {
                state.fetchByPage = [];
                return;
            }
            if(!Array.isArray(fetchFns) ) {
                console.error('addFetchFnsByPage', fetchFns)
                return;
            }
            fetchFns.forEach(f => {
                let fetchFnByPage = state.fetchByPage.find(ff => ff.name == f.name && ff.page == f.page)
                if (fetchFnByPage) {
                    fetchFnByPage.params = f.params
                    fetchFnByPage.eachT = f.eachT
                } else {
                    state.fetchByPage.push(f)
                }
            })
        }
    },
    actions: {
        // setTheme({getters, commit}, theme) {
        //     let storageKey = getters.getUserStorageKey + '.theme'
        //     console.log(storageKey, theme)
        //     localStorage.setItem(storageKey, theme);
        //     commit('setTheme', theme)
        // },
        setLastCall({commit}, args){
            commit('setLastCall', args)
        },
        addFetchFnsByPage({commit}, args){
            commit('addFetchFnsByPage', args)
        },
        fetchAppProps({ getters, dispatch }) {
            if(!getters.apiToken) {
                // return
            }
            let dispatches = consts.fetchAppProps.dispatches || []
            // dispatches.sort(function (a, b) {
            //     return (b.priority || 0) - (a.priority || 0);
            // })
            // console.log('fetchAppProps', dispatches)
            dispatches.forEach(dsp => {
                dispatch(dsp.name, dsp.params)
                    .catch((error) => {
                        if (this.$isDevelopment) console.error(dsp.name, dsp.params, error);
                    })
            })
        },
        fetchByTimer({ getters, dispatch }, params) {
            // console.log('fetchByTimer', params)
            if(!getters.apiToken) {
                // return
            }
            let time = params.time
            let page = params.page
            //by page
            let dispatches = (getters.getFetchByPage || [])
                .filter(dsp => dsp.page == page && dsp.eachT > 0)
            // console.log('fetchByPage', dispatches)
            dispatches.forEach(dsp => {
                let lastCall = getters.lastCall[dsp.name] || 0
                if(time>=lastCall+dsp.eachT) {
                    dispatch(dsp.name, dsp.params)
                        .catch((error) => {
                            if (this.$isDevelopment) console.error(dsp.name, dsp.params, error);
                        })
                    //dispatch('setLastCall', {...dsp, time})
                }
            })
            //by time
            dispatches = consts.fetchByTimer.dispatches || []
            // console.log('fetchByTime', dispatches)
            dispatches.forEach(dsp => {
                let lastCall = getters.lastCall[dsp.name] || 0
                if(time>=lastCall+dsp.eachT) {
                    dispatch(dsp.name, dsp.params)
                        .catch((error) => {
                            if (this.$isDevelopment) console.error(dsp.name, dsp.params, error);
                        })
                    //dispatch('setLastCall', {...dsp, time})
                }
            })
        },
        fetchAppObjects({dispatch, commit, getters}, dispatches) {
            if (!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchAppObjects', time: Date.now() / 1000})
            dispatches.forEach(dsp => {
                if (!dsp?.name) return
                let name = (dsp?.name || '').replace('All', '') //.replace('Changed', '')
                dispatch('setLastCall', {name: name+'All', time: Date.now() / 1000})
                dispatch('setLastCall', {name: name+'Changed', time: Date.now() / 1000})
            })

            // let lsUnits = JSON.parse(localStorage.getItem(getters.getUserStorageKey + '.units'))
            // // console.log('fetchAppObjects lsUnits', lsUnits)
            // new Promise((resolve, reject) => {
            //     if (!lsUnits.length) {
            //         resolve({})
            //         return
            //     }
            //     this.$api.init.getObjects({objs: 'unitsIds'})
            //         .then((response) => {
            //             if (response.status < 400 && !response.data?.error) {
            //                 resolve(response.data)
            //             } else {
            //                 reject(response.data)
            //             }
            //         })
            //         .catch((error) => {
            //             reject(error)
            //             console.error(error);
            //         })
            // })
            //     .then((data) => {
            //         console.log('fetchAppObjects unitsIds', data.unitsIds)
            //         let unitsIds = data.unitsIds || []
            //         if (lsUnits.length && unitsIds.length) {
            //             let units = lsUnits.filter(u => unitsIds.includes(u.id))
            //             commit('setUnits', units)
            //         }
            //         return

            const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
            let fetches = [

                //     new Promise((resolve, reject) => {
                //         this.$api.init.getUnits()
                //             .then((response) => {
                //                 if (response.status < 400 && !response.data?.error) {
                //                     resolve(response.data)
                //                 } else {
                //                     reject(response.data)
                //                 }
                //             })
                //             .catch((error) => {
                //                 reject(error)
                //                 console.error(error);
                //             })
                //     }),
                //     new Promise((resolve, reject) => {
                //         this.$api.init.getUnitsLmsgs()
                //             .then((response) => {
                //                 if (response.status < 400 && !response.data?.error) {
                //                     resolve(response.data)
                //                 } else {
                //                     reject(response.data)
                //                 }
                //             })
                //             .catch((error) => {
                //                 reject(error)
                //                 console.error(error);
                //             })
                //     }),

                    new Promise((resolve, reject) => {
                        this.$api.init.getObjects({objs:'units,lmsgs', ...with_removed})
                            .then((response) => {
                                if (response.status < 400 && !response.data?.error) {
                                    let units = response.data?.units || []
                                    let lmsgs = response.data?.lmsgs || []
                                    commit('setUnits', units)
                                    commit('setUnitsLiteLoad', true)

                                    commit('updateUnitsLmsgs', lmsgs)
                                    commit('setUnitsLmsgsLiteLoad', true)

                                    resolve(true)
                                } else {
                                    reject(response.data)
                                }
                            })
                            .catch((error) => {
                                reject(error)
                                console.error(error);
                                setTimeout(() => {
                                    dispatch('fetchUnitsAllLite', {})
                                    dispatch('fetchUnitsLmsgsAllLite', {})
                                }, 60*1000)
                            })
                    }),
            ]

            dispatches
                .filter(dsp => !(['fetchUnitsAll', 'fetchUnitsLmsgsAll']).includes(dsp.name) )
                .forEach(dsp => {
                    fetches.push(dispatch(dsp.name, dsp.params))
                })

            Promise.all(fetches)
                .then(() => {
                    let fetch = dispatches.map(dsp => {
                        dispatch(dsp.name+'Pages', dsp.params)
                    })
                    return Promise.all(fetch)
                })
                .then(() => {
                    dispatch('setLastCall', {name: 'fetchAppObjects', inprogress: false})
                })
                .catch(() => {
                    // setTimeout(() => {
                    //     dispatch('fetchUnitsInit', {})
                    // }, 60*1000)
                })
        },
        removeIndexToday({commit}){
            commit('removeVideoRecordsIndex')
            commit('removeTripsIndex')
            commit('removeParkingsIndex')
        },
        reloadAppProps({ dispatch, commit }) {
            // if(!getters.apiToken) {
            //     // return
            // }

            let commits = consts.reloadAppProps.commits || []
            // commits.sort(function (a, b) {
            //     return (b.priority || 0) - (a.priority || 0);
            // })
            // console.log('reloadAppProps', commits)
            commits.forEach(cmt => {
                commit(cmt.name, cmt.params)
            })

            let dispatches = consts.reloadAppProps.dispatches || []
            // dispatches.sort(function (a, b) {
            //     return (b.priority || 0) - (a.priority || 0);
            // })
            // console.log('reloadAppProps', dispatches)
            dispatches.forEach(dsp => {
                dispatch(dsp.name, dsp.params)
                    .catch((error) => {
                        if (this.$isDevelopment) console.error(dsp.name, dsp.params, error);
                    })
            })
        },
        clearAllData({commit}) {
            commit('clearUnits')
            commit('clearUnitsLmsgs')
            commit('clearUnitsGroups')

            commit('clearUsers')
            commit('clearCustomizes')

            commit('clearGeopoints')
            commit('clearGeopointsGroups')
            commit('clearGeozones')
            commit('clearGeozonesGroups')

            commit('clearNotifications')

            commit('clearTrips')
            commit('clearParkings')
            commit('clearTracks')

            commit('clearStops')
            commit('clearNotificationsEvents')
            commit('clearEvents')
            commit('clearVideoEvents')
            commit('clearVideoRequests')
            commit('clearVideoRecords')

            commit('setAllGeoitemsShowOnMap', [])
            commit('setAllUnitsShowOnMap', [])

            //give time to redraw the page
            setTimeout(() => commit('clearAppUser'), 300)
        },
    },
    modules: {
        app,
        map,
        security,
        access_rights,
        units_hwtypes,

        units,
        drivers,
        appointments,
        units_lmsgs,
        video_statuses,
        units_groups,

        users,
        customize,

        geoitems,
        geozones,
        geozones_groups,
        geopoints,
        geopoints_groups,

        trips,
        parkings,
        idlings,
        tracks,

        stops,
        notifications_history,
        events,
        screenshots,
        video_events,
        video_requests,
        video_records,

        reports_templates,
        reports,

        // notifications_types,
        notifications,

        tive_shipments,
        tive_shipment_alerts,
        tive_shipment_tracks,
        tive_shipment_templates,
        tive_devices,
        tive_carriers,
        tive_alert_presets,
        tive_locations,
        tive_users,
        shipments_types,

        maintenance,//->maintenanceServices
        maintenanceVendors,
        maintenanceContacts,
        maintenanceContactGroups,
        maintenanceParts,
        maintenanceServiceTasks,
        maintenanceIssues,
        maintenanceWorkOrders,
        maintenanceInspections,
        maintenanceHistory,
        maintenanceNotifications,
        maintenanceExpenses,

        statistics,
        weather,

        unit_locator,
    }
})
