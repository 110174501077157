// import Vue from 'vue'
import consts from "@/consts"

const additional_reports_templates = !consts.app.$isPrometheus ? [] : [
    {id: 'vision_md', icon: 'empty', title: 'Vision MD', type: '', params: {}, disabled: true},
    {id: 'maintenance', icon: 'empty', title: 'Maintenance', type: '', params: {}, disabled: true},
    {id: 'driver_core_card', icon: 'empty', title: 'Driver Score Card', type: '', params: {}, disabled: true},
    {id: 'geofonce_visits', icon: 'empty', title: 'Geofonce Visits', type: '', params: {}, disabled: true},
    {id: 'posted_speed_limit', icon: 'empty', title: 'Posted Speed Limit', type: '', params: {}, disabled: true},
    {id: 'vision_alerts', icon: 'empty', title: 'Vision Alerts', type: '', params: {}, disabled: true},

    // {id: 4, icon: 'reports_fuel', title: 'Fuel'},
    // {id: 5, icon: 'reports_driving', title: 'Driving Behavior'},
    // {id: 6, icon: 'empty', title: 'Vision MD'},
    // {id: 7, icon: 'empty', title: 'ELD'},
    // {id: 8, icon: 'reports_alarms', title: 'Alarms'},
    // {id: 9, icon: 'reports_idling', title: 'Idling'},
    // {id: 10, icon: 'empty', title: 'GeoFonce'},
];

export default {
    state: {
        reports_templates: [
            {id: 'msgs', icon: 'reports_raw', title: 'Raw data', type: 'system', params: {unit: true, units_group: false}},
            {id: 'trips', icon: 'reports_trips', title: 'Trips', type: 'system', params: {unit: true, units_group: true}},
            {id: 'idlings', icon: 'reports_idling', title: 'Idling', type: 'system', params: {unit: true, units_group: true}},
            {id: 'trips-parkings', icon: 'reports_parkings', title: 'Trips/Parkings', type: 'system', params: {unit: true, units_group: true}},
            {id: 'statistics', icon: 'reports_statistics', title: 'Statistics', type: 'system', params: {unit: true, units_group: true}},
            ...additional_reports_templates,
        ],
    },
    actions: {
        //fetchReportsTemplates
    },
    mutations: {},
    getters: {
        getReportsTemplates(state) {
            return state.reports_templates
        },
        getReportsTemplatesById(state) {
            return state.reports_templates
                .reduce((rtsById, report) => {
                    rtsById[report.id] = report
                    return rtsById
                }, {})
        },

    }
}