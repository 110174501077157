// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {
    state: {
        tracks_index: [],
        tracks: [],
    },
    actions: {
        fetchTracks4Date({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !args.unitId || !args.date) {
                    return reject(false)
                }
                let index = getters.getTracksIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                if (index) {
                    return resolve(true)
                }
                dispatch('setLastCall', {name: 'fetchTracks4Date', time: Date.now() / 1000})

                this.$api.tracks.get4Date(args.unitId, args.date, args.params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeTrack', response.data)
                            commit('setTracksIndex', {...args, count: response.data.length})
                            if (!args.unitId && response.data.length) {
                                // debugger
                            }
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTracks4Date', inprogress: false})
                    });
            })
        },
        fetchTracks4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }
                let track = getters.getTracks.find(t => (t.unit_id == args.unitId && t.timeFrom == args.from && t.timeTo == args.to))
                if (track) {
                    return resolve(true)
                }

                dispatch('setLastCall', {name: 'fetchTracks4Period', time: Date.now() / 1000})

                this.$api.tracks.get4Period(args.unitId, args.from, args.to, args.params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeTrack', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTracks4Period', inprogress: false})
                    });
            })
        },
        fetchTracksDistance4Period({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }

                dispatch('setLastCall', {name: 'fetchTracksDistance4Period', time: Date.now() / 1000})

                this.$api.tracks.get4Period(args.unitId, args.from, args.to, args.params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTracksDistance4Period', inprogress: false})
                    });
            })
        },
        reloadTracks({commit, dispatch}, args) {
            commit('clearTracks')
            return dispatch('fetchTracks4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        setTracksIndex(state, params) {
            let i = state.tracks_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.tracks_index.push(Object.freeze({...params}))
            } else {
                state.tracks_index[i] = Object.freeze({...state.tracks_index[i], ...params})
            }
        },
        storeTrack(state, nTrack) {
            let track = state.tracks.find(t => {
                return nTrack.unit_id == t.unit_id
                && nTrack.timeFrom == t.timeFrom
                && nTrack.timeTo == t.timeTo
            })
            if(!track){
                state.tracks.push({...nTrack})
            } else {
                track = {...track, ...nTrack}
            }

            //vue recommended
            // const chunks = arraySplitIntoChunks(nTracks)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.tracks.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearTracks(state) {
            state.tracks = [];
            state.tracks_index = [];
        },
    },
    getters: {
        getTracksIndex(state) {
            return state.tracks_index
        },
        getTracks(state) {
            return state.tracks
        },
    }
}
