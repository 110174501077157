import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {
    track: (route, router, unitId, date) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('track', route);
            return false
        }
        date = date || store.getters.getDateToday
        router.push({
            name: consts.routerNames.tracks.main,
            params: {
                unitId,
                date,
            },
            query: {}
        })
    },
    trackUnitId: (route, router, unitId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackUnitId', route);
            return false
        }
        let date = route?.params?.date
        router.push({
            name: consts.routerNames.tracks.main,
            params: {
                unitId,
                date
            },
            query: {...route.query}
        })
    },
    trackDate: (route, router, date) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackDate', route);
            return false
        }
        let unitId = route?.params?.unitId
        router.push({
            name: consts.routerNames.tracks.main,
            params: {
                unitId,
                date,
            },
            query: {...route.query}
        })
    },
    trackXid: (route, router, type, trackXid, timeFrom, timeTo, trackColor) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackXid', route);
            return false
        }
        let unitId = route?.params?.unitId
        let date = route?.params?.date
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: consts.routerNames.tracks.xid,
            params: {
                unitId,
                date,
                type,
                trackXid,
                timeFrom,
                timeTo,
                trackColor,
            },
            query: {...route.query}
        })
    },
    trackColor: (route, router, trackColor) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackColor', route);
            return false
        }
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: route.name,
            params: {
                ...route?.params,
                trackColor,
            },
            query: {...route.query}
        })
    },
    trackEvent: (route, router, trackEventType, trackEventXid) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackEvent', route);
            return false
        }
        router.push({
            name: consts.routerNames.tracks.event_xid,
            params: {
                ...route.params,
                trackEventType,
                trackEventXid,
            },
            query: {...route.query}
        })
    },
    trackMoveOnTrack: (route, router, speed) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackColor', route);
            return false
        }
        let params = {...route?.params}
        if(speed) {
            params.speed = speed
        } else if(params.speed) {
            delete params.speed
        }
        router.push({
            name: route.name,
            params,
            query: {...route.query}
        })
    },
    trackVideoEventPopup: (route, router, trackEventType, trackEventXid) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackVideoEventPopup', route);
            return false
        }
        router.push({
            name: consts.routerNames.tracks.video.event,
            params: {
                ...route.params,
                trackEventType,
                trackEventXid,
            },
        })
    },
    trackVideoRequestPopup: (route, router, trackEventType, trackEventXid) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('trackVideoEventPopup', route);
            return false
        }
        let unitId = route?.params?.unitId
        let date = route?.params?.date
        let type = route?.params?.type
        let trackId = route?.params?.trackXid
        router.push({
            name: consts.routerNames.tracks.video.request,
            params: {
                unitId,
                date,
                type,
                trackId,
                trackEventType,
                trackEventXid,
            },
        })
    },
    returnToParent:(route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.tracks.main+' returnToParent', route);
            return false;
        }
        let name = route.meta?.parentRouteName || consts.routerNames.tracks.main;
        router.push({
            name: name ,
            params: {
                ...route.params,
            },
            query: {...route.query}
        })
    },
    liteTemplate: (route, router, lite, noList=false) => {
        if(route.meta?.mainRouteName !== consts.routerNames.tracks.main) {
            if(this.$isDevelopment) console.warn('liteTemplate', route, lite);
            return false;
        }
        let routeName = route.name.split('.')
        let lastRoute = routeName[routeName.length - 1]
        routeName = lastRoute === 'lite' ? routeName.filter(n => n !== lastRoute) : routeName
        if(lite){
            routeName.push('lite')
        }
        router.push({
            name: routeName.join('.'),
            params: {
                ...route.params,
                noList: noList ? 'no-list' : '',
            },
            query: {...route.query}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: 'tracks/:noList?/:unitId(\\d+)?/:date?',
        name: consts.routerNames.tracks.main,
        meta: {
            list: 'TracksList',
            mainRouteName: consts.routerNames.tracks.main,
            showMapInfo: true,
            showMap: true,
            isLimited: false,
            layers: {
                ...consts.defaultSettingsLayers,
                geoitems: {visible: false, showInList: true, showLayer: true},
                tracks: {visible: true, showInList: true, showLayer: true},
            },
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/intuit/portal/TracksPage.vue'),
        children:[
            {
                path: 'lite',
                name: consts.routerNames.tracks.main+'.lite',
                meta:{
                    mainRouteName: consts.routerNames.tracks.main,
                    parentRouteName: consts.routerNames.tracks.main,
                    pageClass: 'track-lite',
                },
            },
            {
                path: ':type/:trackXid:/:timeFrom/:timeTo/:trackColor?/:speed?',
                name: consts.routerNames.tracks.xid,
                meta:{
                    mainRouteName: consts.routerNames.tracks.main,
                    parentRouteName: consts.routerNames.tracks.main,
                },
                // props: {
                // },
                // components: {
                // }
                children:[
                    {
                        path: ':trackEventType/:trackEventXid',
                        name: consts.routerNames.tracks.event_xid,
                        meta:{
                            mainRouteName: consts.routerNames.tracks.main,
                            parentRouteName: consts.routerNames.tracks.xid,
                        },
                        // props: {
                        // },
                        // components: {
                        // }
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.tracks.event_xid+'.lite',
                                meta:{
                                    mainRouteName: consts.routerNames.tracks.main,
                                    parentRouteName: consts.routerNames.tracks.main,
                                    pageClass: 'track-lite',
                                },
                            },
                            {
                                path: 'video-event-popup',
                                name: consts.routerNames.tracks.video.event,
                                meta:{
                                    mainRouteName: consts.routerNames.tracks.main,
                                    parentRouteName: consts.routerNames.tracks.event_xid,
                                    sectionPopupByRoute: true
                                },
                                props: {
                                    sectionPopupByRoute: (route) => ({eventXid: route.params.trackEventXid})
                                },
                                components: {
                                    sectionPopupByRoute: () => import('@/components/video_events/sectionInfo/VideoEventView.vue')
                                }
                            },
                            {
                                path: 'video-request-popup',
                                name: consts.routerNames.tracks.video.request,
                                meta:{
                                    mainRouteName: consts.routerNames.tracks.main,
                                    parentRouteName: consts.routerNames.tracks.event_xid,
                                    sectionPopupByRoute: true
                                },
                                props: {
                                    sectionPopupByRoute: (route) => ({eventXid: route.params.trackEventXid})
                                },
                                components: {
                                    sectionPopupByRoute: () => import('@/components/video_requests/sectionInfo/VideoRequestView.vue')
                                }
                            }
                        ]
                    },
                    {
                        path: 'lite',
                        name: consts.routerNames.tracks.xid+'.lite',
                        meta:{
                            mainRouteName: consts.routerNames.tracks.main,
                            parentRouteName: consts.routerNames.tracks.main,
                            pageClass: 'track-lite',
                        },
                    },
                ]
            },
        ]
    },
];

export default routes;